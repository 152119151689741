* {
  letter-spacing: 0.0125em;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
.col-span {
  grid-column: span 3 / span 3;
  display: flex;
  justify-content: end;
  align-items: start;
}
.logout {
  color: white;
  margin-top: 20px;
  justify-self: end;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 4px;
  background-color: transparent;
  border: solid 1px rgb(231 98 243 / 1);
}
.logout:hover {
  background-color: rgb(231 98 243 / 0.5);
}
.container {
  width: 100%;
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
  grid-column: span 6 / span 6;
}

.box {
  box-shadow: 0px 90.9961px 72.7969px rgba(41, 72, 152, 0.05),
    0px 58.9789px 42.6334px rgba(41, 72, 152, 0.037963),
    0px 35.0503px 23.1872px rgba(41, 72, 152, 0.0303704),
    0px 18.1992px 11.8295px rgba(41, 72, 152, 0.025),
    0px 7.4145px 5.9316px rgba(41, 72, 152, 0.0196296),
    0px 1.68511px 2.86469px rgba(41, 72, 152, 0.012037);
  max-width: 492px;
  width: 95%;
  border-radius: 8px;
  height: fit-content;
  backdrop-filter: blur(50px);
}

.box-header {
  width: 100%;
}

.box-header > span {
  display: flex;
  justify-content: space-between;
}

.box-header > span > span {
  font-size: 13px;
}

.banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 340px;
  border-radius: 8px;
  height: 140px;
}

.card-title {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25rem;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
}

.select-dropdown {
  display: flex;
  align-items: center;
}

.select-dropdown > img {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  margin-right: 10px;
}

.box-content {
  padding: 16px;
}

.rate-limit-text {
  color: grey;
  font-size: 13px;
  font-weight: 300;
  word-break: break-word;
  letter-spacing: 1px;
  line-height: 20px;
}

.rate-limit-text > span {
  margin-left: 2px;
  font-weight: 500;
}

.address-input {
  display: flex;
  align-items: center;
  color: #333;
  height: 45px;
  margin-bottom: 8px;
  border-radius: 5px;
}

.address-input > input {
  background-color: transparent;
  border-style: none;
  outline: none;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0 12px;
}

.beta-alert {
  color: #ff5252;
  font-size: 14px;
  border: thin #ff5252 solid;
  text-align: center;
  border-radius: 4px;
  margin-top: 20px;
}

.button-container {
  display: flex;
  justify-content: space-between;
}

.send-button {
  margin-top: 20px;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  border: 0px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.15);
  cursor: pointer;
  text-transform: uppercase;
}

/* .send-button:hover {
    background-color: #333;
} */

.send-button-disabled {
  color: rgba(255, 255, 255, 0.406);
  margin-top: 20px;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  border: 0px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.15);
  cursor: not-allowed;
  text-transform: uppercase;
}

.bold-text {
  font-size: small;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.536);
}

.back-button {
  margin-top: 10px;
  height: 36px;
  width: 100px;
  padding: 0 16px;
  border: 0px;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
}
