/* Modal Overlay (background that dims the screen) */
.modal-overlay {
  position: fixed; /* Position the overlay fixed to the screen */
  top: 0;
  left: 0;
  overflow: none;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.3
  ); /* Semi-transparent black background */
  display: flex; /* Use Flexbox to center the modal */
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the modal is on top */
  backdrop-filter: blur(10px);
  animation: fadeIn 0.3s ease-in; /* Animation to fade in */
}

.modal-box {
  margin: auto auto;
  position: absolute;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: white;
  font-size: 16px;
  /* background-color: rgb(27 26 27 / 1); */
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 20px; */
  border-radius: 8px; /* Rounded corners */
}

.desc {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
